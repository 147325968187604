export enum AssetType {
  TeamLogo = 'team_logo',
  OutlineLogo = 'outline_logo',
  Wordmark = 'wordmark',
  GameCard = 'gamecard',
  TriviaSponsorLogo = 'trivia_sponsor_logo',
  PollSponsorLogo = 'poll_sponsor_logo',
  TeamScreamSponsorLogo = 'teamscream_sponsor_logo',
  StatsSponsorLogo = 'stats_sponsor_logo',
}
