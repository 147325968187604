import {useState} from 'react';
import {useNavigate, useLocation} from 'react-router-dom';

import DefaultLayout from '../../components/layouts/DefaultLayout';
import {HeaderLarge} from '../../components/typography/HeaderLarge';
import {Button} from '../../components/buttons/Button';
import {Spacer} from '../../components/layouts/Spacer';
import {BodyText} from '../../components/typography/BodyText';
import routes from '../../constants/routes.constants';

export default function PreLoginError() {
  const {search} = useLocation();
  function useQuery() {
    return new URLSearchParams(search);
  }
  const query = useQuery();
  const error = query.get('error') || 'Unknown';
  const navigate = useNavigate();
  const [retrying, setRetrying] = useState(false);

  const retryLogin = () => {
    setRetrying(true);
    navigate(`${routes.initialLaunch}${search}`);
  };
  return (
    <DefaultLayout hideRadioToggle={true}>
      <HeaderLarge>Error:</HeaderLarge>
      <BodyText>{error}</BodyText>
      <Spacer top="2rem">
        <Button type="button" onClick={retryLogin} disabled={retrying}>
          Retry
        </Button>
      </Spacer>
      <Spacer top="2rem" />
    </DefaultLayout>
  );
}
