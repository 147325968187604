import styled from '@emotion/styled';
import Modal from 'react-modal';
import VideoPlayer from '../../components/media/VideoPlayer';
import GameViewWrapper from '../../wrappers/GameViewWrapper';
import InGameLayout from '../../components/layouts/InGameLayout';
import Interaction from '../interaction/Interaction';
import useJoinedTeam from '../../hooks/useJoinedTeam';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '../../redux/rootReducer';
import Chat from '../chat/Chat';
import useJoinGameCallback from '../../hooks/useJoinGameCallback';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { geolocateUser } from '../../redux/modules/geolocation';
import { AnyAction } from '@reduxjs/toolkit';
import { resetGameState, resetJoinGameStatus, setActiveMillis } from '../../redux/modules/game';
import { Spacer } from '../../components/layouts/Spacer';
import { BodyText } from '../../components/typography/BodyText';
import { black } from '../../theme/vars';
import { Button } from '../../components/buttons/Button';
import { defaultModal } from '../../theme/modal';
import routes from '../../constants/routes.constants';
import useQuery from '../../hooks/useQuery';
import DefaultLayout from '../../components/layouts/DefaultLayout';
import { TailSpin } from 'react-loader-spinner';
import { trackEventData } from '../../utils/analytics';
import {
  GAME_TIME_BACKGROUND,
  GAME_TIME_ENGAGEMENT
} from '../../constants/analytics.constants';
import { GAME_ENGAGEMENT_DELAY_MS } from '../../constants/Global.constants';
import usePageVisibility from '../../hooks/usePageVisibility';

const Container = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
  @media screen and (orientation: portrait) {
    flex-direction: column;
  }
  @media screen and (orientation: landscape) {
    flex-direction: row;
  }
  height: 100%;
  background-color: grey;
`;

const VideoContainer = styled.div`
  display: flex;
  flex-direction: column;
  @media screen and (orientation: portrait) {
    flex-shrink: 1;
  }
  @media screen and (orientation: landscape) {
    flex: 1;
  }
  align-items: center;
  justify-content: center;
  position: relative;
`;

const ChatContainer = styled.div`
  height: 67%;
  display: flex;
  @media screen and (orientation: portrait) {
    flex-grow: 1;
  }
  @media screen and (orientation: landscape) {
    flex: 1;
  }
  background-color: black;
  overflow: hidden;
`;

const CenterContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
`;

const InGame = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {gameId: gameIdFromUrl, feedName} = useParams<{gameId: string, feedName?: string}>();

  const {userData, getUserError} = useSelector((state:RootState) => state.user);
  const {activeTeam, nextGame, activeAppTeamId} = useSelector((state: RootState) => state.teams);
  const {joinedGame, error: joinGameError, loading, activeMillis} = useSelector((state: RootState) => state.game);
  const {geolocating, geocodingData} = useSelector((state: RootState) => state.geolocation);
  const joinGame = useJoinGameCallback(gameIdFromUrl);
  const joinedTeam = useJoinedTeam();
  const {search} = useLocation();
  const query = useQuery(search);
  const bypassStadiumKey = query.get('bypassKey');

  const isVisible = usePageVisibility();
  let activeDurationMillis = useRef<number>(activeMillis);
  const timeoutRef = useRef<NodeJS.Timeout | undefined>();


  useEffect(() => {
    if ((!geocodingData || !joinedGame) && nextGame && !geolocating && !loading && userData && !getUserError) {
      dispatch(geolocateUser(bypassStadiumKey) as unknown as AnyAction);
      joinGame();
    }
  },[userData, bypassStadiumKey, dispatch, geocodingData, geolocating, joinGame, joinedGame, loading, nextGame, getUserError]);

  useEffect(() => {
    return () => {
      //Note: this may cause endless renders if join game fails it will just keep retrying
      joinGameError && dispatch(resetJoinGameStatus() as unknown as AnyAction);
    };
  }, [joinGameError, dispatch]);

  // Prevent screen from dimming or locking. Not supported on all devices (including iOS < 16.4); fails silently.
  useEffect(() => {
    let wakeLockSentinel: WakeLockSentinel | null = null;

    const requestScreenLock = async () => {
      wakeLockSentinel = await navigator?.wakeLock?.request('screen');
    }

    requestScreenLock();

    return () => {
      if (wakeLockSentinel) {
        wakeLockSentinel.release();
      }
    }
  }, []);

  useEffect(() => {
    return () => {
      dispatch(setActiveMillis(activeDurationMillis.current));
    }
  });

  useEffect(() => {
    if (!isVisible && userData && userData.id && gameIdFromUrl) {
      const logBackground = (lastActiveDuration: number) => {
        timeoutRef.current && clearTimeout(timeoutRef.current);
        timeoutRef.current = setTimeout(() => {
          activeDurationMillis.current = lastActiveDuration + GAME_ENGAGEMENT_DELAY_MS;
          trackEventData(GAME_TIME_BACKGROUND, {
            user_id: userData.id,
            game_id: gameIdFromUrl,
            extend_session: 1,
            duration_msec: activeDurationMillis.current,
            engagement_time_msec: GAME_ENGAGEMENT_DELAY_MS,
          });
          logBackground(activeDurationMillis.current);
        }, GAME_ENGAGEMENT_DELAY_MS)
      }
      logBackground(activeDurationMillis.current);
    } else if (isVisible && userData && userData.id && gameIdFromUrl) {
      const logForeground = (lastActiveDuration: number) => {
        timeoutRef.current && clearTimeout(timeoutRef.current);
        timeoutRef.current = setTimeout(() => {
          activeDurationMillis.current = lastActiveDuration + GAME_ENGAGEMENT_DELAY_MS;
          trackEventData(GAME_TIME_ENGAGEMENT, {
            user_id: userData.id,
            game_id: gameIdFromUrl,
            extend_session: 1,
            duration_msec: activeDurationMillis.current,
            engagement_time_msec: GAME_ENGAGEMENT_DELAY_MS,
          });
          logForeground(activeDurationMillis.current);
        }, GAME_ENGAGEMENT_DELAY_MS)
      }
      logForeground(activeDurationMillis.current);
    }
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [gameIdFromUrl, isVisible, userData]);

  useEffect(() => {
    //Test to see if the game for the user's team is in a live/test state
    if (nextGame && activeAppTeamId && userData) {
      const isHomeTeam = nextGame.homeTeamId === activeAppTeamId;
      const isAwayTeam = nextGame.awayTeamId === activeAppTeamId;
      const isHomeGameStateLive = isHomeTeam && nextGame.homeGameState === 'live';
      const isHomeGameStateTest = isHomeTeam && nextGame.homeGameState === 'test' && userData.testUser;
      const isAwayGameStateLive = isAwayTeam && nextGame.awayGameState === 'live';
      const isAwayGameStateTest = isAwayTeam && nextGame.awayGameState === 'test' && userData.testUser;

      if ((isHomeTeam && !(isHomeGameStateLive || isHomeGameStateTest)) || (isAwayTeam && !(isAwayGameStateLive || isAwayGameStateTest))) {
        dispatch(resetGameState());
        navigate(routes.home);
      }
    }
  }, [nextGame, activeAppTeamId, userData, dispatch, navigate]);

  const isReadyToRender = useCallback(() => {
    const isReady = joinedGame && geocodingData && !geolocating;
    return isReady;
  }, [geocodingData, geolocating, joinedGame])

  const InGameContent = () => {
    if (!isReadyToRender() || !gameIdFromUrl) {
      return (
        <DefaultLayout>
          <CenterContainer>
            <TailSpin color="#00BFFF" />
          </CenterContainer>
        </DefaultLayout>
      )
    } else {
      return (
        <GameViewWrapper>
          <Interaction />
          <InGameLayout activeTeam={activeTeam} gameId={gameIdFromUrl}>
            <Container>
              <VideoContainer>
                <VideoPlayer
                  joinedTeamId={joinedTeam?.id}
                  type="application/x-mpegURL"
                />
              </VideoContainer>
              {!feedName &&
              <ChatContainer>
                <Chat />
              </ChatContainer>}
            </Container>
          </InGameLayout>
          <Modal
            isOpen={!!joinGameError}
            onRequestClose={() => !!joinGameError && resetJoinGameStatus()}
            style={defaultModal}
            contentLabel="Join Game Error Modal"
            appElement={document.querySelector('#root') as HTMLElement}>
            <Spacer top="1rem" bottom="2rem">
              <BodyText color={black}>{joinGameError}</BodyText>
            </Spacer>
            <Button
              onClick={() => {
                resetJoinGameStatus();
                joinGame();
              }}>
              Try again?
            </Button>
          </Modal>
        </GameViewWrapper>
      )
    }
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const returnMemo = useMemo(() => <InGameContent />, [isReadyToRender, gameIdFromUrl, activeTeam, joinedTeam?.id, feedName, joinGameError]);
  return (
    returnMemo
  );
};

export default InGame;
